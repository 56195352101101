// src/components/page/APropos.js
import React from 'react';
import AproposImage from '../../assets/Apropos.webp';  // Assurez-vous que le chemin vers l'image est correct
import LocaImage from '../../assets/loca.webp';  // Assurez-vous que le chemin vers l'image est correct
import MercadierLogo from '../../assets/apropos/logoMercadier.svg';
import StAstierLogo from '../../assets/apropos/logoStAstier.svg';
import AkterreLogo from '../../assets/apropos/logoAkterre.webp';
import OcreDeFranceLogo from '../../assets/ocredefrance.webp';
import CobatisseusesLogo from '../../assets/coBat.webp';
import './styles/APropos.css'; // Assurez-vous d'avoir un fichier CSS pour styliser le composant

function APropos() {
  return (
    <div className="apropos-container">
      <div className="presentation-container">
        <div className="text-content">
          <h1>À la rencontre de Sarah Eugenie Bouchemella</h1>
          <p>L'âme créative derrière <em>Les Enduits d'Eugénie</em>.</p>
          <p>
  Passionnée par les couleurs, les textures et les matériaux naturels, Sarah transforme les murs en véritables œuvres d'art. Sa curiosité pour les matériaux écologiques et son talent pour la finition l'ont conduite à suivre des formations en <strong>éco-construction</strong> au <a href="https://batipolelimouxin.fr/" target="_blank" rel="noopener noreferrer">Batipôle en Limouxin</a>, avant de se spécialiser dans les <strong>enduits naturels</strong> à la renommée <a href="https://www.artematieres.com/" target="_blank" rel="noopener noreferrer">École Européenne de l'Art et des Matières d'Albi</a>.
</p>

          <p>
            Aujourd'hui, Sarah s'est spécialisée dans les techniques les plus raffinées : <strong>enduits de finition</strong>, 
            <strong> décoration murale en terre</strong>, <strong>chaux</strong>, <strong>plâtre</strong>, mais aussi dans l'élégant 
            <strong> béton ciré</strong> et le <strong>stuc</strong>. Chaque projet qu'elle entreprend est une nouvelle occasion 
            pour elle de redonner vie à des espaces en les sublimant de manière unique.
          </p>
        </div>
        <div className="image-container">
          <img src={AproposImage} alt="Sarah Eugenie Bouchemella" className="apropos-image" />
        </div>
      </div>

      <div className="intervention-container">
        <div className="image-container">
          <img src={LocaImage} alt="Zone d'intervention" className="loca-image" />
        </div>
        <div className="text-content">
          <h2>Secteur d'intervention</h2>
          <p>
  Installée au cœur du <strong>pays Vigannais</strong>, selon votre projet, Sarah peut vous accompagner sur le chantier dans un rayon de 
  <strong> 200 km autour de Montpellier</strong>, que ce soit pour des projets de rénovation ou de construction neuve. 
  Elle intervient également dans des lieux d’exception comme <strong>L’Isle-sur-la-Sorgue</strong>, où son savoir-faire contribue à sublimer les bâtis anciens et modernes.
</p>

        </div>
      </div>

      <div className="partnership-container">
        <h2>En bonne compagnie</h2>
        <p>
  Savoir s'entourer des meilleurs partenaires pour assurer une qualité irréprochable est un atout majeur, notamment avec <a href="https://www.ocres-de-france.com/fr/" target="_blank" rel="noopener noreferrer">Ocre de France</a> pour des pigments naturels époustouflants, <a href="https://www.mercadier.fr/" target="_blank" rel="noopener noreferrer">Mercadier</a> pour des bétons cirés haut de gamme, <a href="https://www.saint-astier.com/" target="_blank" rel="noopener noreferrer">Saint Astier</a> pour des chaux naturelles de qualité, 
  et <a href="https://akterre.fr/" target="_blank" rel="noopener noreferrer">Akterre</a> pour des enduits terre exceptionnels. 
  Sarah fait également partie du collectif <a href="https://lescobatisseuses.wixsite.com/lescobatisseuses" target="_blank" rel="noopener noreferrer">Les Co'Batisseuses</a>, 
  un réseau de femmes bâtisseuses engagées dans la construction écologique.
</p>

        <div className="logos-container">
          <a href="https://www.saint-astier.com/" target="_blank" rel="noopener noreferrer"><img src={StAstierLogo} alt="Logo Saint Astier" className="partner-logo" /></a>
          <a href="https://akterre.fr/" target="_blank" rel="noopener noreferrer"><img src={AkterreLogo} alt="Logo Akterre" className="partner-logo" /></a>
          <a href="https://www.mercadier.fr/" target="_blank" rel="noopener noreferrer"><img src={MercadierLogo} alt="Logo Mercadier" className="partner-logo" /></a>
          <a href="https://ocres-de-france.com" target="_blank" rel="noopener noreferrer"><img src={OcreDeFranceLogo} alt="Logo Ocre de France" className="partner-logo" /></a>
          <a href="https://lescobatisseuses.wixsite.com/lescobatisseuses" target="_blank" rel="noopener noreferrer"><img src={CobatisseusesLogo} alt="Logo Les Co'Batisseuses" className="partner-logo" /></a>
        </div>
      </div>
    </div>
  );
}

export default APropos;
