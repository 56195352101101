import React, { useEffect } from 'react';
import './styles/ServiceModal.css';

const ServiceModal = ({ show, onClose, service, onPrevious, onNext }) => {

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'ArrowLeft') {
        onPrevious();
      } else if (e.key === 'ArrowRight') {
        onNext();
      }
    };

    if (show) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, onClose, onPrevious, onNext]);

  if (!show) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()} // Empêche la fermeture lors d'un clic à l'intérieur de la modal
      >
        <button className="modal-close" onClick={onClose}>×</button>

        <div
          className="modal-image"
          style={{ backgroundImage: `url(${service.backgroundImage})` }}
        />

        <div className="modal-body">
          <h2>{service.title}</h2>
          <p>{service.fullDescription}</p>

          <div className="modal-navigation">
            <button onClick={onPrevious} className="modal-nav prev">❮</button>
            <button onClick={onNext} className="modal-nav next">❯</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
