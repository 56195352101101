import React, { useState, useEffect } from 'react';
import './styles/Galerie.css';

const Galerie = ({ onOpenLightbox }) => {
  const [galleryData, setGalleryData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({
    badigeons: true,
    betonsCires: true,
    enduitsChaux: true,
    enduitsTerre: true,
  });

  // Charger les données du fichier JSON
  useEffect(() => {
    fetch('/assets/galleryData.json')
      .then((response) => response.json())
      .then((data) => {
        let allImages = [
          ...data.badigeons,
          ...data.betonsCires,
          ...data.enduitsChaux,
          ...data.enduitsTerre,
        ];

        // Mélanger les images
        allImages = allImages.sort(() => Math.random() - 0.5);

        // Ajouter un identifiant unique à chaque image
        allImages = allImages.map((image, idx) => ({ ...image, id: idx }));

        setGalleryData(allImages);
      })
      .catch((error) => console.error('Erreur lors du chargement des images :', error));
  }, []);

  // Fonction pour ajouter la classe 'abs-pos' après 0.5s pour simuler le délai
  const applyAbsolutePosition = (index) => {
    setTimeout(() => {
      const element = document.getElementById(`gallery-item-${index}`);
      if (element) {
        element.classList.add('abs-pos');
      }
    }, 500); // Délai de 0,5s
  };

  // Filtrer les images visibles
  const visibleImages = galleryData.filter((image) => selectedCategories[image.category]);

  // Fonction pour ouvrir la lightbox avec les images filtrées
  const openLightbox = (image) => {
    const indexInVisibleImages = visibleImages.findIndex((img) => img.id === image.id);
    if (indexInVisibleImages !== -1) {
      onOpenLightbox(image.src, indexInVisibleImages, visibleImages);
    } else {
      console.error("L'image sélectionnée est introuvable dans les images visibles.");
    }
  };

  // Gestion des filtres par catégorie
  const handleCheckboxChange = (category) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <div className="gallery-page-container">
      <h1>Galerie de photos</h1>
      <p>Découvrez quelques-uns de nos projets récents et laissez-vous inspirer.</p>

      {/* Filtres par catégorie */}
      <div className="filter-container">
        <label>
          <input
            type="checkbox"
            name='badigeons'
            checked={selectedCategories.badigeons}
            onChange={() => handleCheckboxChange('badigeons')}
          />
          Badigeons
        </label>
        <label>
          <input
            type="checkbox"
            name='Bétons Cires'
            checked={selectedCategories.betonsCires}
            onChange={() => handleCheckboxChange('betonsCires')}
          />
          Bétons Cirés
        </label>
        <label>
          <input
            type="checkbox"
            name='Enduits à la Chaux'
            checked={selectedCategories.enduitsChaux}
            onChange={() => handleCheckboxChange('enduitsChaux')}
          />
          Enduits à la Chaux
        </label>
        <label>
          <input
            type="checkbox"
            name='Enduits Terre'
            checked={selectedCategories.enduitsTerre}
            onChange={() => handleCheckboxChange('enduitsTerre')}
          />
          Enduits Terre
        </label>
      </div>

      {/* Galerie d'images */}
      <div className="gallery-container">
        {galleryData.map((image, index) => {
          const isVisible = selectedCategories[image.category]; // Affiche ou cache selon le filtre

          // Si l'image est masquée (filtrée), appliquer le délai pour `position: absolute`
          if (!isVisible) {
            applyAbsolutePosition(index);
          }

          return (
            <div
              id={`gallery-item-${index}`}
              className={`gallery-item ${isVisible ? 'show' : 'hide'}`} // Ajoute la classe "hide" ou "show"
              key={image.id}
              onClick={() => openLightbox(image)} // Passe l'image entière
            >
              <img src={image.src} alt={`Projet ${index + 1}`} />
              <div className="overlay">
              <h3>
  {image.category
    .replace(/([A-Z])/g, ' $1') // Ajoute un espace avant les majuscules
    .replace(/\b\w/g, (match) => match.toUpperCase()) // Met une majuscule à chaque mot
  }
</h3>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Galerie;
