import React from 'react';
import logo from '../assets/logo2.webp';
import './styles/HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection({ isAside }) {
  return (
    <div className={`hero-container ${isAside ? 'aside' : ''}`}>
      <div className="hero-overlay">
        <div className="logocontainer">
          <Link to="/">
            <img
              src={logo}
              alt="Les Enduits d'Eugénie"
              className={`hero-logo ${isAside ? 'logo-aside' : ''}`}
            />
          </Link>
        </div>
        <h1>Donnez à vos espaces une finition exceptionnelle</h1>
        <p>Experte des enduits naturels & décoratifs</p> <p>Qualité, précision, durabilité</p>
        <Link to="/contact">
          <button className="cta-button">Demandez un devis gratuit</button>
        </Link>
      </div>
    </div>
  );
}

export default HeroSection;
