import React, { useState, useEffect } from 'react';
import './styles/Blog.css';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [underConstruction] = useState(true);

  useEffect(() => {
    if (underConstruction) return; // Ne pas récupérer les posts si le blog est en construction

    const fetchPosts = async () => {
      try {
        const response = await fetch('https://les-enduits-d-eugenie.onrender.com/api/posts');
        if (!response.ok) throw new Error('Erreur lors de la récupération des posts.');

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des posts :', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [underConstruction]);

  if (underConstruction) {
    return (
      <div className="blog-container">
        <h1>Blog</h1>
        <p className="construction-message">
          Ce blog est actuellement en construction. Revenez bientôt pour découvrir nos articles !
        </p>
      </div>
    );
  }

  if (loading) return <p>Chargement des posts...</p>;
  if (error) return <p>Erreur : {error}</p>;

  return (
    <div className="blog-container">
      <h1>Blog</h1>
      <div className="posts-grid">
        {posts.map((post) => (
          <div key={post.id} className="post">
            {post.media_type === 'VIDEO' && post.cover_image_url ? (
              <div className="video-cover-wrapper">
                <img
                  className="post-image"
                  src={post.cover_image_url}
                  alt={`Couverture vidéo de ${post.original_caption || 'Publication Instagram'}`}
                />
                <div className="play-button-overlay">▶</div>
              </div>
            ) : (
              <img
                className="post-image"
                src={post.media_url}
                alt={post.original_caption || 'Publication Instagram'}
              />
            )}
            <p className="post-caption">{post.enriched_caption || 'Pas de contenu enrichi disponible.'}</p>
            <a
              href={post.permalink}
              target="_blank"
              rel="noopener noreferrer"
              className="post-link"
            >
              Voir sur Instagram
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
