import React, { useState } from 'react';
import Navbar from './components/Navbar.js';
import './App.css';
import MainContent from './components/page/MainContent.js';
import Lightbox from './components/Lightbox.js';

function App() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);

  // Fonction pour ouvrir la lightbox
  const handleLightboxOpen = (imageSrc, index, images) => {
    setLightboxImage(imageSrc);
    setCurrentIndex(index);
    setLightboxImages(images); // Charger uniquement les images filtrées visibles
    setLightboxOpen(true);
  };

  // Fonction pour fermer la lightbox
  const handleLightboxClose = () => {
    setLightboxOpen(false);
    setLightboxImage(null);
  };

  return (
    <div className="App">
      <Navbar />
      <MainContent onOpenLightbox={handleLightboxOpen} /> {/* Passe handleLightboxOpen à MainContent */}
      {lightboxOpen && (
        <Lightbox
          image={lightboxImage}
          index={currentIndex}
          images={lightboxImages} // Passe les images visibles à la lightbox
          setCurrentIndex={setCurrentIndex}
          onClose={handleLightboxClose}
        />
      )}
    </div>
  );
}

export default App;
