import React, { useState } from 'react';
import './styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    commune: '',
    chantierType: '',
    travauxType: '',
    piece: '',
    surface: '',
    message: '',
  });

  const [files, setFiles] = useState([]);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });
  
    if (files.length > 0) {
      Array.from(files).forEach((file) => {
        formDataToSend.append('files', file);
      });
    }
  
    // Afficher les données du formulaire avant l'envoi
    for (let pair of formDataToSend.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const response = await fetch('https://les-enduits-d-eugenie.onrender.com/api/send-email', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi de l'e-mail.");
      }

      console.log('E-mail envoyé avec succès.');
      alert('Message envoyé avec succès !'); // Afficher une alerte
      setSent(true);
      setError(null);

      // Réinitialiser le formulaire après l'envoi
      setFormData({
        name: '',
        email: '',
        phone: '',
        location: '',
        commune: '',
        chantierType: '',
        travauxType: '',
        piece: '',
        surface: '',
        message: '',
      });
      setFiles([]);
      document.getElementById('files').value = null; // Réinitialiser l'input file
    } catch (err) {
      console.error("Erreur lors de l'envoi de l'e-mail :", err);
      setError('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  return (
    <div className="contact-container">
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2>Contactez-nous</h2>
        <div className="form-grid">
          <div className="form-group">
            <label htmlFor="name">Nom :</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Votre nom*"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Adresse Email :</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Votre email*"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Téléphone :</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Votre numéro de téléphone*"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="location">Localisation :</label>
            <select
              id="location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            >
              <option value="">Sélectionnez votre localité</option>
              <option value="gard">Gard</option>
              <option value="herault">Hérault</option>
              <option value="lozere">Lozère</option>
              <option value="aveyron">Aveyron</option>
              <option value="vaucluse">Vaucluse</option>
              <option value="other">Autre</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="commune">Commune :</label>
            <input
              type="text"
              id="commune"
              name="commune"
              value={formData.commune}
              onChange={handleInputChange}
              placeholder="Votre commune"
            />
          </div>

          <div className="form-group">
            <label htmlFor="chantierType">Type de chantier :</label>
            <select
              id="chantierType"
              name="chantierType"
              value={formData.chantierType}
              onChange={handleInputChange}
            >
              <option value="">Sélectionnez le type de chantier</option>
              <option value="neuf">Neuf</option>
              <option value="renovation">Rénovation</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="travauxType">Type de travaux :</label>
            <select
              id="travauxType"
              name="travauxType"
              value={formData.travauxType}
              onChange={handleInputChange}
            >
              <option value="">Sélectionnez le type de travaux</option>
              <option value="facade">Façade</option>
              <option value="interieur">Intérieur</option>
              <option value="sol">Sol</option>
              <option value="plafond">Plafond</option>
              <option value="autre">Autre</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="piece">Pièce concernée :</label>
            <select
              id="piece"
              name="piece"
              value={formData.piece}
              onChange={handleInputChange}
            >
              <option value="">Sélectionnez la pièce</option>
              <option value="cuisine">Cuisine</option>
              <option value="salle_de_bain">Salle d'eau</option>
              <option value="salon">Salon</option>
              <option value="chambre">Chambre</option>
              <option value="cave">Cave</option>
              <option value="autre">Autre</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="surface">Surface concernée (m²) :</label>
            <input
              type="number"
              id="surface"
              name="surface"
              value={formData.surface}
              onChange={handleInputChange}
              placeholder="Surface en m²"
            />
          </div>

          <div className="form-group">
            <label htmlFor="files">Documents à joindre :</label>
            <input
              type="file"
              id="files"
              name="files"
              multiple
              onChange={handleFileChange}
              accept=".zip,.pdf,.webp,.webp,.webp"
            />
          </div>
        </div>

        <div className="form-group full-width">
          <label htmlFor="message">Message :</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="Pour un devis, veuillez expliquer votre projet en détail et joindre les documents nécessaires (plans, photos, etc.)"
            required
          ></textarea>
        </div>

        <button type="submit">Envoyer</button>

        {sent}
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default Contact;
