import React, { useEffect, useCallback } from 'react';
import './styles/Lightbox.css';

const Lightbox = ({ image, index, onClose, images = [], setCurrentIndex }) => {
  
  // Fonction pour passer à l'image suivante
  const handleNext = useCallback(() => {
    if (images.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Passe à l'image suivante, boucle au début
    }
  }, [images, setCurrentIndex]);

  // Fonction pour passer à l'image précédente
  const handlePrev = useCallback(() => {
    if (images.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); // Passe à l'image précédente, boucle à la fin
    }
  }, [images, setCurrentIndex]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // Gestion des touches de navigation (clavier)
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        handleNext();
      } else if (e.key === 'ArrowLeft') {
        handlePrev();
      } else if (e.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleNext, handlePrev, handleClose]);

  // Rendu de la lightbox avec les boutons de navigation et l'image actuelle
  return (
    <div className="lightbox-overlay" onClick={handleClose}>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={handleClose}>×</span>
        <span className="prev" onClick={handlePrev}>‹</span>
        <img src={images[index]?.src} alt="Lightbox" className="lightbox-image" />
        <span className="next" onClick={handleNext}>›</span>
      </div>
    </div>
  );
};

export default Lightbox;
