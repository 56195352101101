import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import HeroSection from '../HeroSection.js';
import APropos from './APropos.js';
import Contact from './Contact.js';
import Galerie from './Galerie.js';
import Service from './Service.js';
import Blog from './Blog.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './styles/Transitions.css';

function MainContent({ onOpenLightbox }) {
  const [isAside, setIsAside] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (location.pathname === '/') {
        setIsAside(false);
      } else {
        setIsAside(!mobile);
      }
    };

    handleResize(); // Initialiser l'état

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  return (
    <div className={`main-container ${isAside ? 'with-aside' : ''}`}>
      {!(isMobile && location.pathname !== '/') && <HeroSection isAside={isAside} />}
      <div className="page-content">
        <TransitionGroup className="page-transition-container">
          <CSSTransition
            key={location.key}
            classNames="slide"
            timeout={500}
            unmountOnExit
          >
            <Routes location={location}>
              <Route path="/apropos" element={<APropos />} />
              <Route path="/services" element={<Service />} />
              <Route
                path="/galerie"
                element={<Galerie onOpenLightbox={onOpenLightbox} />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default MainContent;
